import React, { useEffect, useState } from "react";

const PrivacyEN = props => {
  const [value, setValue] = useState();
  useEffect(() => {});
  return (
    <div className="privacyCont">
      <div>
        <h1>Privacy & Cookie Policy</h1>
        <br />
        <strong>Premessa</strong>
        <br />
        La presente informativa illustra come Convivio SA tratta i tuoi Dati
        Personali e come funzionano i cookie quando utilizzi la nostra APP di
        tracciamento per il contenimento del COVID-19. Il documento può essere
        modificato a nostra discrezione in qualsiasi momento, la sua versione
        più aggiornata è disponibile ogni qualvolta accedi alla nostra APP.{" "}
        <br />
        <br />
        <strong>1. Titolare e Responsabile del trattamento dei dati</strong>
        <br />
        Convivio SA <br />
        Via della Posta 2 - 6900 Lugano, Ticino (Svizzera)
        <br />
        Email: management@convivio-lugano.ch <br />
        <br />
        <strong>2. Raccolta e trattamento dei dati</strong>
        <br />
        I Dati Personali raccolti su base volontaria compilando il modulo di
        tracciamento online sono:
        <br />
        nome e cognome
        <br />
        telefono
        <br />
        indirizzo
        <br />
        <br />
        I tuoi Dati Personali vengono elaborati esclusivamente per adempiere
        agli obblighi previsti dalla direttiva Svizzera per il contenimento del
        COVID-19, e sono trattati in forza del tuo consenso su base volontaria.
        <br />
        <br />
        I dati raccolti potranno - ove strettamente necessario - essere
        conservati al di fuori della Svizzera, nel rispetto del principio di
        trasparenza secondo il Commissario Federale per la Protezione dei Dati e
        l'Informazione (FDPIC), il Consiglio Federale, o sulla base di clausole
        contrattuali relative alle norme UE per la trasmissione di dati
        personali a paesi terzi. <br />
        <br />
        <strong>3. Destinatari dei dati</strong>
        <br />
        I tuoi Dati Personali potranno essere comunicati esclusivamente alle
        autorità cantonali svizzere competenti che ne faranno esplicita
        richiesta ad convivio. <br />
        <br />
        <strong>4. Conservazione e protezione dei dati</strong>
        <br />
        I Dati Personali sono trattati e conservati per un periodo di 14
        (quattordici) giorni, in conformità alla direttiva Svizzera per il
        contenimento del COVID-19. Trascorso tale periodo il programma di
        tracciamento cancella automaticamente i dati.
        <br />
        <br />
        Conserviamo e proteggiamo la trasmissione dei tuoi Dati Personali e
        successivamente anche la loro memorizzazione con adeguate misure
        organizzative tecniche - ove necessario anche con l'uso della
        crittografia. <br />
        <br />
        <strong>5. Cookies</strong>
        <br />
        La nostra APP utilizza esclusivamente un cookie tecnico che salva
        temporaneamente sul tuo dispositivo una copia dei Dati Personali - che
        fornisci su base volontaria - al solo fine di facilitare la compilazione
        del modulo di tracciamento online ai successivi utilizzi.
        <br />
        <br />
        <strong>6. Riferimenti legali</strong> <br />
        La Privacy & Cookie Policy di convivio è redatta sulla basa di
        molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del
        Regolamento EU 2016/679 e riguarda esclusivamente la nostra APP di
        tracciamento.
        <br />
        <br />
        Per ulteriori informazioni dell’informativa contattarci via email a
        management@convivio-lugano.ch o via posta a Convivio SA - Via della
        Posta 2 - 6900 Lugano, Ticino (Svizzera).
      </div>
    </div>
  );
};

export default PrivacyEN;
